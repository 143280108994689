import { english } from './english'

export const portuguese = {

  navigation: [
    { id: 'header', label: 'Start', href: '#' },
    { id: 'testimonials', label: 'Depoimentos', href: '#testimonials' },
    { id: 'contact', label: 'Contato', href: '#contact' },
    { id: 'treatments', label: 'Tratamento', href: '#treatments' },
    { id: 'about', label: 'Mais sobre Claudio', href: '#about' },
  ],

  languages: {
    title: 'Languages',
    body: [
      { label: 'English', href: '/en', code: 'en' },
      { label: 'Português', href: '/pt', code: 'pt' },
      { label: 'Svenska', href: '/sv', code: 'sv' },
      { label: 'Français', href: '/fr', code: 'fr' },
      { label: 'Español', href: '/es', code: 'es' },
    ]
  },

  currentWork: {
    text: `Claudio J De Menezes trabalha como Terapeuta Convidado (Visiting  practitioner)
            e pode ser reservado através dos estabelecimentos abaixo`,
    list: english.currentWork.list
  },

  intro: {
    title: 'Claudio J De Menezes',
    subtitle: 'Massoterapeuta e reflexólogo',
    image: english.intro.image,
    body: [
      `A Terapia especial desenvolvida por Cláudio J. de Menezes no ramo de massagens,
      rendeu a ele o reconhecimento como um dos 10 melhores terapuetas do mundo, segundo o jornal britânico The Telegraph.
      Cláudio possui 25 anos de experiência como reflexologista e terapeuta corporal.`
      ,
      `Cláudio possui uma habilidade única de proporcionar uma atmosfera discreta e aconchegante para todos os seus clientes.
      Para Cláudio, essa atmosfera é de suma importância para que ele possa ter a sensibilidade necessária para
       desenvolver um trabalho individualizado de acordo com a demanda de cada cliente. Sua personalidade marcante aliada à sua forma
       única de trabalho têm contribuido para o seu sucesso em sua jornada profissional. Uma sessão com Cláudio sempre deixa uma boa impressão e um sorriso reconfortante.
       Venha conferir!`
    ]
  },

  about: {
    title: 'mais sobre Claudio',
    image: english.about.image,
    body: [
      `Claudio nascido no Brasil em Minas Gerais,
      vive na Europa desde 1987 onde trabalhou inicialmente como construtor de órgãos na França. Estudou terapia corporal, anatomia,
      fisiologia e reflexologia com professores chineses na França e também na Espanha.`
      ,
      `Antes de abrir seu companhia Plexus em Estocolmo,
      Claudio exerceu suas atividades como autônomo em uma série de países como Letônia, Bélgica, Espanha e Brasil.`
      ,
      `Rapidamente pelos resultados obtidos em reflexologia Cláudio se tornou um terapeuta de grande prestigio e internacionalmente conhecido
      por gostar de viajar. Hoje, continua rodando o mundo oferecendo tratamentos e aulas de técnica  de  reflexologia em diversos paises por onde passa.`
      ,
      `Você que já sabe como eu sou e como trabalho, ou você que está curioso sobre a reflexologia e o que ela pode fazer por você, marque
      já a sua consulta! Estou disposto a cuidar de você com dedicação e profissionalismo.`
      ,
      `Nós Vemos em breve.`
    ]
  },

  treatments: {
    title: 'Reflexologia - Estar em plena Harmonia',
    subtitle: 'For Health and Wellbeing',
    images: english.treatments.images,
    body: [
      `O objetivo do Claudio é dar a cada cliente um tratamento personalizado de grande e alta qualidade.
      Melhor saúde,maior bem estar  e uma melhor conhecimento de seu próprio corpo.`
      ,
      `Sabemos que muitas pessoas vivem uma vida em que o pensamento em sua própria saúde é secundário.
      Muitas pessoas dormem mal, comem mal e tempo para praticar exercícios fisicos é dificil de encontrar.`
      ,
      `Para você, a pergunta é como você vive e como se sente.
      Não é necessário que você faça grandes mudanças, mas se você escolher a maneira correta de mudar sua vida os resultados se notarao progressivamente.
      Ademais com algumas Sessões de Reflexologia de uma ou duas horas por mês poderá fazer uma grande  diferença  na sua saúde.`
      ,
      `Atualmente, a reflexologia está "em moda" no ocidente, porém,
      é uma técnica oriental que vem sendo aplicada com resultados satisfatórios há mais de 3.000 anos. Reflexologia significa colocar o
      individuo no centro. Corpo, mente e espírito. Todos esses três componentes são muito importantes durante o tratamento, daí o fato da
      reflexologia ser conhecida como um tratamento holístico.`
      ,
      `A imprensa ocidental tem publicado cada vez mais matérias sobre os benefícios da reflexologia, uma técnica amplamente utilizada na China e em outros países asiáticos,
      e o fato de ter-se tornado conhecida pelo mundo afora é uma prova de sua eficiência.`
      ,
      `Pés em primeiro lugar! E, através deles, todo o seu corpo será tratado.
      Através dos pontos reflexos dos pés, o terapeuta deverá ativar ou relaxar os órgãos internos que trabalham "muito" ou "muito pouco",
      contribuindo para seu bem-estar da cabeça aos pés e de dentro para fora!`
      ,
      `Tratamento simples, com resultados impressionantes. Embora o método possa parecer fácil, o paciente sentirá,
      logo após o primeiro tratamento, uma sensação de harmonia interior ou uma sensação profunda de relaxamento!`
      ,
    ]
  },

  testimonials: {
    title: 'Depoimentos',
    list: english.testimonials.list
  },

  references: {
    title: 'Referências',
    list: [
      {
        title: 'Les Eccos',
        body: 'Encontro e tratamento com o reflexologo Claudio No Spa da Sixsences Vale do Douro em Portugal'
      },
      {
        title: 'The Telegraph',
        body: `Em 2010 Claudio foi nomeado pelo jornal britânico The Telegraph como um dos dez melhores terapeutas do mundo.`
      },
      {
        title: 'Vogue Brasil',
        body: `No  Brasil seu país natal foi mencionado na revista Vogue Brasil em novembro de 2010.`
      },
      {
        title: 'O Corpo Ballet',
        body: `Ja acompanhou e trabalhou como terapeuta com o famoso Grupo de ballet O Corpo no Brasil e na Europa.`
      },
      {
        title: "Raison d'Etre",
        body: `Trabalhou para a empresa Raison D etre no Grande Hotel de Estocolmo`
      },
      {
        title: 'Sturebadet',
        body: `Trabalhou durante dois anos no exclusivo Spa Sturebadet no centro da cidade de Estocolmo`
      },
      {
        title: 'The Huffington Post',
        body: `Em setembro de 2011 foi mencionado em outro jornal Britânico o The Huffington Post UK`
      }
    ]
  },

  contact: {
    title: 'Contato',
    body:   `Devido ao grande número de viagens,sobre tudo agora como membro do grupo de (Visiting praticioners) da empreza de Spas e Hoteis Six Senses
    nem sempre é possível obter contato comigo pelo telefone, por isso peço por gentileza que me enviem um e-mail,
    caso eu não esteja acessível via telefone. Tenha certeza  que seu  e-mail será respondido o mais breve possível.`,
    list: english.contact.list
  }
}
