import introductionPic from '../../media/introduction.jpg'
import profilePic from '../../media/profile.jpg'
import feet1 from '../../media/feet1.png'
import feet2 from '../../media/feet2.png'
import feet3 from '../../media/feet3.png'

export const english = {

  navigation: [
    { id: 'header', label: 'Start', href: '#' },
    { id: 'testimonials', label: 'Testimonials', href: '#testimonials' },
    { id: 'contact', label: 'Contact', href: '#contact' },
    { id: 'treatments', label: 'Treatments', href: '#treatments' },
    { id: 'about', label: 'About me', href: '#about' },
  ],

  languages: {
    title: 'Languages',
    body: [
      { label: 'English', href: '/en', code: 'en' },
      { label: 'Português', href: '/pt', code: 'pt' },
      { label: 'Svenska', href: '/sv', code: 'sv' },
      { label: 'Français', href: '/fr', code: 'fr' },
      { label: 'Español', href: '/es', code: 'es' },
    ]
  },

  currentWork: {
    text: `Claudio J De Menezes works as a visiting
          practitioner and can be booked through the establishments below`,
    list: [
      {
        as: "a",
        variant: "cta",
        target: "_blank",
        href: "https://www.sixsenses.com/en/wellness-spa/visiting-practitioners-calendar/claudio-j-de-menezes",
        text: 'Six Sences'
      },
      {
        as: "a",
        variant: "cta",
        target: "_blank",
        href: "https://www.fourseasons.com/es-es/madrid/spa/visiting-practitioners/?fbclid=IwAR3LkhbTc6k_dipUCdiQiQNEEdMD_VBnRiCVq-iUNngsrCUJkk6IVZAzAYs",
        text: 'Four Seasons Madrid'
      }
    ]
  },

  intro: {
    title: 'Reflexologist \u0026 Massage therapist',
    subtitle: "Claudio J De Menezes",
    body: [
      `Claudio J De Menezes healing touch and special treatments has earned him the recognition of being rated as one of the top ten
      therapists in the world by The Telegraph. He has twenty years of experience as a reflexologist and body therapist.`
      ,
      `Claudio has an unique capacity to make an atmosphere of comfort and discretion.
      It’s important to him to always have a sensitive and appropriate touch adjusted to whom he meets.
      This and his absolute wonderful personality has made his way working worldwide. An encounter with him often leaves a print and a warm smile.`
    ],
    image: {
      src: introductionPic,
      alt: 'Claudio J De Menezes massaging feet',
      label: 'Claudio J De Menezes'
    },
  },

  about: {
    title: 'A message from Claudio',
    body: [
      `I was born in Minas Gerais in Brazil and came to Europe in 1987.
      I studied body therapy, anatomy, physiology and reflexology under Chinese teachers in France and Spain.`
      ,
      `Before opening Plexus Sanitas in Stockholm, I worked as a reflexologist and massage therapist in Latvia,
      Belgium, Spain and Brazil. I quickly became an internationally appreciated and respected therapist.
      I love to travel, do therapeutic treatments and spread my knowledge about reflexology.`
      ,
      `For those of you who already know me and how I work, or for those of you who are curious about
      reflexology and how you can benefit from it, you are welcome to contact me to set up an appointment for treatment.`
      ,
      `I can promise a relaxing moment for yourself, your health and your wellbeing.`
      ,
      `I am ready to care of you...`
    ],
    image: {
      src: profilePic,
      alt: 'Claudio J De Menezes',
      label: 'Claudio J De Menezes'
    }
  },

  treatments: {
    title: 'Achieve Harmony with Reflexology',
    subtitle: 'For Health and Wellbeing',
    body: [
      `It is well known that many people live there lives without paying enough attention caring for there wellbeing.
      Many do not get enough sleep, eat unhealthy food, exercise to little, or not at all.`
      ,
      `For you the question is how you live - and how you feel. Maybe you only need minor changes in your life in order to achieve better health.
      At least if you choose to make the right changes.
      Through sessions of reflexology once or twice a month, you can achieve great gains towards better health and wellbeing.`
      ,
      `A trend today! But it has been helping people for more than 3000 years. Reflexology means that your whole body is focused on.
      Body, soul and spirit are all as important in reflexology. Thats why the treatment is also known as a holistic treatment.
      Despite many seeing reflexology as a trendy alternative therapeutic method  seen in media, it is not particularly modern - quite the opposite!
      In China and other parts of Asia it has been practiced as far back as 1000 BC.
      The fact that it is still practiced and that it has now spread across the world is a testament that it works and a that it is indeed
      helping people achive better health and better lives. Feet first! But the entire body gets activated.`
      ,
      `A reflexologist focuses on your feet. Through working with areas under your feet the different organs in your body activates
      or relaxes depending on if they are ”to tense” or ”to relaxed”. Through the patterns of your feet your organs, glands and other parts can
      be activated.`
      ,
      `Reflexology is a perfect example of what sometimes is referred to as a holistic method. It helps you feel great from the bottom upp,
      and from the inside out. A simple treatment with impressive results. Even though the method is simple,
      the patient feels happier and more content even after the very first treatment.
      The first result from a real treatment in reflexology is foremost a deep feeling of relaxation.
      This feeling is also the most important feeling if you want to move on and get help with possible problems in your inner organs.
      The best thing of all is that even if you request help for a specific problem,
      you still get a multitude of effects when the wellbeing spreads in your entire endocrine system gives you new energy bringing new
      perspectives to your life.`
      ,
      `Claudio’s aims to give each customer a personally adapted treatment of the highest standard,
      better health,  greater wellbeing and a better body awareness.`
    ],
    images: [
      { src: feet1, alt: 'Hands massaging feet' },
      { src: feet2, alt: 'Hands massaging feet' },
      { src: feet3, alt: 'Hands massaging feet' },
    ]
  },

  testimonials: {
    title: 'Testimonials',
    list: [
      {
        made_by: 'Natalie - New York, USA',
        body: `I am still on cloud 9 from that wonderful treatment this afternoon!
        As you worked on the different areas of my feet, i felt myself melting into ever deeper stages of relaxation and indeed consciousness.
        You have the softest yet most precise hands and movement.
        And your tremendous knowledge and willingness to share and explain the process and the relationship between the areas of tension and the places on my feet was so helpful and illuminating.
        Just sad that I am bot able to work with you longer —I know my back tension, foot pain and overall stress level would be transformed completely if possible!
        Hope to meet you again at another Six Senses — until then, obrigada for an amazing treatment!`
      },
      {
        made_by: 'Heston Blumenthal - London, UK',
        body: `Dear Claudio, I wanted to say thank you for having such a big impact on my life.
        I believe that your work has the power to change people’s lives.
        In this modern age, we like to use buzz words like holistic
        and wellbeing but they have been so overused and underestimated.
        Your connectivity with and care for human beings along with your knowledge of the body,
        and sense of touch and feel, particularly when it comes to reflexology is something rarely found today.
        For me what you do heralds the future of medicine`
      },
      {
        made_by: 'Anna Bjurstam, Wellness Pioneer, Six Senses - Stockholm, Sweden',
        body: `I have had the pleasure of working with Claudio J De Menezes at Raison D’Etre Spa in Stolkholm Grand Hotel for many years.
        Claudio is a friendly, reliable and conciencious practitioner.
        From observing him with his clients at the spa it is clear to me that he has a genuine interest in people.
        Taking extra time to ensure each client has the very best experience possible.
        He achieves this by treating all clients as individuals,
        taking time to understand what they want from their treatment and delivering this in a relaxed and professional manner.
        Claudio had many clients who returned for subsequent visits and this is a testament to the quality and effectiveness of the treatments they had received from him.
        Due to Claudios discreet and approachable character he was able to treat people from all walks of life including royalty, politicians and famous artistes.`
      },
      {
        made_by: 'Alexius Feit - Paris, France',
        body: `I met Claudio at the Six Senses Laamu (Maldives Islands)
        in April 2021 after catching some shoulder pain during a long surfing session.
        After a couple of minutes of Brazilian introduction,
        I knew I was in competent professional hands.
        Adapting Chinese foot reflexology through a touch of Brazilian inspiration
        and know-how Claudio knows what he’s doing.
        The foot treatment is such that he can either put you literally to sleep
        in half hour or send small electric impulses to the areas of the body
        that have pain and/or trouble symptoms. Expertly he recreates harmony and
        relaxation in your body. How he does it remains in his hands,
        like a musical organ-synchronizer Claudio puts his knowledge at our reach via
        expertise/experience for a result-oriented performance linking excellence/exemplarity.
        An unexpected and rare treatment by a wonderful person that will be highly remembered.`
      },
      {
        made_by: 'Patrick Wahlberg - Stockholm, Sweden',
        body: `I worked as a CEO for a large day spa in Stockholm and then moved on as project director for one the
        largest and most exclusive spa and fitness consultancy firms in the world. I worked world wide for almost ten years and met many many therapists.
        Once in a blue moon you meet a therapist that outshines everyone else. Claudio De Menezes is one the most talented therapists I have ever met.
        Not only does he have a vast knowledge, but he has a touch and a almost supernatural gift in knowing exactly what to do. He is also a true gentleman,
        a rare quality these days.`
      },
      {
        made_by: 'Sara Singh - New York, USA',
        body: `I highly recommend Claudio to anyone seeking a reflexologist. After treatments from him I feel deeply relaxed, light and agile at the same time.
        His treatments have helped me with neck tension and headaches. I found Claudio to be very in tune and sensitive to where I had issues.
        His sessions are very calming and soothing (I fell asleep each time), but have also been a great learning experience as Claudio explained what he
        found in different parts of my foot and how those areas corresponded to other parts of my body (I was very curious and asked lot of questions).`
      },
      {
        made_by: 'Kerstin Rosén - Stockholm, Sweden',
        body: `About 15 years ago, during an especially heavy and stressful work load,
        I had the opportunity to receive regular reflexology treatments from Claudio.
        The effect and relief were immediate for me. A wonderful relaxation spread throughout
        my body the minute Claudio began to massage. Since then I’ve been going to Claudio every time he is home in Sweden.
        Nothing else provides me with such well-being.`
      },
      {
        made_by: 'François Lhermite - Paris, France',
        body: `I’ve known Claudio for a long time now. I did receive many of his reflexology sessions in New York, Paris,
        Rio de Janeiro or Stockholm. As a flight attendant, it is always a wonderful way to escape from my daily routine and to take care of myself.
        Claudio makes you relax and treats you at the same time. He's always looking for a relaxing atmosphere.
        His huge energy radiations make you stronger and his kind attention is pampering you so much that you do not want him to leave. Thank you bro’ !
        François.`
      },
      {
        made_by: 'Maximilian - Tokyo, Japan',
        body: `In Brazil I had the pleasure of meeting Claudio and the privilege of receiving his reflexology treatment.
        He intuitively perceived underlying problems affecting me on different levels upon palpation of both feet and through his presence,
        experience and skill provided me with deep relaxation, cleansing and inevitable healing after therapy.
        Claudio is the best reflexology practitioner I’ve ever known, and recommend him highly to those who are troubled by ailments of any kind.`
      },
      {
        made_by: 'Svetlana Nikiforova - Moscow, Russia',
        body: `Dear Claudio. Appreciate your energy and depth. Send u my warm words with gratitude, I choose the best.
        Best teachers, professional, specialists.
        And I also keep focused on my health and often visit osteopathic,
        kinesiology, mental energy physicians,
        but for the first time in my life I met such an outstanding practitioner,
        who combines all those areas of expertise. Our internal body health starts from our feet,
        they are our starting points for our energy rise.
        Claudio worked with my feet for an hour, but it feels like
        I had 4 hour body massage including hands, head, legs all together.
        Deep relaxation and pleasure`
      },
  ]
 },

 references: {
  title: 'References',
  list: [
    {
        title: 'Les Eccos',
        body: 'One treatment by Claudio in Sixsences Douro Valley Portugal '
    },
    {
        title: 'The Telegraph',
        body: `Named as one of the top 10 therapists in the world`
     },
     {
        title: 'Vogue Brasil',
        body: `In his homeland Brazil, he has been published in Vogue Brasil`

      },
      {
        title: 'O Corpo Ballet',
        body: `Toured with the famous brazillian ballet “O Corpo” as their massage therapist`
      },
      {
        title: "Raison d'Etre",
        body: `Worked at Raison d'Etre at Grand Hotel in Stockholm`
      },
      {
        title: 'Sturebadet',
        body: `Worked for two years at the exlusive spa Sturebadet in the centrum of Stockholm`
      },
      {
        title: 'The Huffington Post',
        body: `In september 2011 Claudio was mentioned in The Huffington Post UK`
      }
    ]
  },

  contact: {
    title: 'Contact Me',
    body: `As a visiting practitioner and new member of the crew at Six Senses spa and hotels around the world
    I'll be traveling a lot and not always able to answer the phone. So for me to be able to get back to you as quick as posssible please send me an email. See you soon!`,
    list: [
      { label: 'Instagram', href: 'https://www.instagram.com/reflexoterapiaclaudius1/' },
      { label: 'Facebook', href: 'https://www.facebook.com/ClaudiusReflexology' },
      { label: 'Skype', target: '_blank', href: 'https://join.skype.com/invite/kvkwj4Q3GaAG?fbclid=IwAR2u6U4AsCnKpI-p2l-bCSAVIGlxetqwmTJAP8TUlkaNa95avhDDTTbPAmU' },
      { label: 'claudius6tus@yahoo.se', href: 'mailto:claudius6tus@yahoo.se' },
      { label: '(ES) +34 662 204 247' },
      { label: '(WhatsApp) +34 662 204 247' }
    ]
  }

}
