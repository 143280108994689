import React, { Component } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from 'react-router-dom'
import { Site, Theme, PlexusSanitasTheme } from '@ossy-se/components-react'
import {
  english,
  spanish,
  french,
  portuguese,
  swedish
} from './data/languages'

const router = createBrowserRouter([
  {
    path: '/',
    loader: () => redirect('/en')
  },
  {
    path: '/en',
    element: <Site {...english}/>
  },
  {
    path: '/english',
    loader: () => redirect('/en')
  },
  {
    path: '/es',
    element: <Site {...spanish}/>
  },
  {
    path: '/spanish',
    loader: () => redirect('/es')
  },
  {
    path: '/fr',
    element: <Site {...french}/>
  },
  {
    path: '/french',
    loader: () => redirect('/fr')
  },
  {
    path: '/pt',
    element: <Site {...portuguese}/>
  },
  {
    path: '/portuguese',
    loader: () => redirect('/pt')
  },
  {
    path: '/sv',
    element: <Site {...swedish}/>
  },
  {
    path: '/swedish',
    loader: () => redirect('/sv')
  },
])

export const App = () => (
  <Theme theme={PlexusSanitasTheme}>
    <RouterProvider router={router} />
  </Theme>
)
