import { english } from './english'

export const swedish = {

  navigation: [
    { id: 'header', label: 'Start', href: '#' },
    { id: 'testimonials', label: 'Vittnesmål', href: '#testimonials' },
    { id: 'contact', label: 'Kontakt', href: '#contact' },
    { id: 'treatments', label: 'Behandlingar', href: '#treatments' },
    { id: 'about', label: 'Om Claudio', href: '#about' },
  ],

  languages: {
    title: 'Språk',
    body: [
      { label: 'English', href: '/en', code: 'en' },
      { label: 'Português', href: '/pt', code: 'pt' },
      { label: 'Svenska', href: '/sv', code: 'sv' },
      { label: 'Français', href: '/fr', code: 'fr' },
      { label: 'Español', href: '/es', code: 'es' },
    ]
  },

  currentWork: {
    text: `Claudio J De Menezes arbetar som besökande Therapeuft (Visiting  practitioner)
            och kan bokas genom följande etableringar`,
    list: english.currentWork.list
  },

  intro: {
    title: 'Claudio J De Menezes',
    subtitle: 'Massage- och zonterapeut',
    image: english.intro.image,
    body: [
      `Claudio J De Menezes helande handlag och speciella behandlingar har givit honom erkännande
      som en av världens 10 bästa terapeuter i the Telegraph’s rankning.
      Han har 25 års erfarenhet som zonterapeut och kroppsterapeut.`
      ,
      `Claudio har en unik förmåga att skapa en atmosfär där klienten känner sig fullständigt
      avslappnad och bekväm. Det är viktigt för honom att alltid känsligt anpassa sig till
      just den person han möter. Hans underbara, både starka och inkännande personlighet har hjälpt
      honom i hans arbete runt om i välden. Ett möte med Claudio lämnar gärna ett avtryck och ett varmt leende.`

      ]
  },

  about: {
    title: 'Lite mer om mig',
    image: english.about.image,
    body: [
      `Jag föddes i Minas Gerais i Brasilien och kom till Europa 1987 för att arbeta som orgelbyggare i Frankrike.
      Jag studerade sedan anatomi, fysiologi och zonterapi för kinesiska lärare i Frankrike och Spanien.`
      ,
      `Innan jag startade Plexus Sanitas i Stockholm, arbetade jag som massage- och zonteterapeut i Lettland,
      Belgien, Spanien och Brasilien. Jag blev snabbt en internationellt uppskattad och respekterad terapeut,
      som tycker om att resa, ge terapeutiska behandlingar och dela med mig av min kunskap om zonterapi.`
      ,
      `Du som redan känner mig och mitt sätt att arbeta, och du som är nyfiken på zonterapi och vad den kan göra för dig,
      är varmt välkommen att kontakta mig och boka tid för en behandling. Jag är redo att ta emot.
      Jag lovar en avslappnande stund för dig, din hälsa och ditt välbefinnande och hoppas få välkomna dig snart.`
      ,
      `Vi ses!`
    ]
  },

  treatments: {
    title: 'Uppnå harmoni genom zonterapi',
    subtitle: 'FÖR HÄLSA OCH VÄLBEFINNANDE ',
    images: english.treatments.images,
    body: [
      `Det är väl känt att många människor lever sina liv utan att tänka på sitt välbefinnande.
      Man får inte tillräckligt med sömn, äter ohälsosam mat, motionerar för lite eller inte alls.`
      ,
      `Frågan är hur du lever ditt liv – och hur du mår. Kanske behöver du bara göra små förändringar för att uppnå bättre hälsa.
      En eller ett par zonterapibehandlingar per månad kan hjälpa dig att nå bättre hälsa och välbefinnande.`
      ,
      `Zonterapi är trendigt idag, men faktum är att det har hjälpt människor i 3000 år.`
      ,
      `Reflexology means that your whole body is focused on. Den meningen verkar inte vara avsluta.
      Jag förstår den inte riktigt. Vad är kroppen fokuserad på? Eller menar du att man fokuserar på hela kroppen?`
      ,
      `Kropp, själ och ande är alla lika viktiga i zonterapi. Det är därför den kallas holistisk.
      Fast många ser zonterapi som en trendig, alternativ terapi som man läser om i media, är den inte så modern,
      snarare tvärt om. I Kina och andra delar av Asien har den praktiserats sedan 1000 år före Kristus. Det faktum att den
      fortfarande praktiseras
      och har spritt sig runt världen visar att den fungerar och verkligen hjälper människor till bättre hälsa och bättre liv.`
      ,
      `Fötterna först! men hala kroppen aktiveras.`
      ,
      `En zonterapeut fokuserar på dina fötter. Genom att olika områden på dina fotsulor bearbetas,
      aktiveras eller slappnas dina inte organ av, beroende på om de är
      för mycket eller för lite aktiva. Genom mönster på fotsulorna kan organen, körtlarna och andra delar aktiveras.`
      ,
      `Zonterapi är ett utmärkt exempel på vad som ibland kallas en holistisk behandlingsmetod.
      Den får dig att känna välbefinnande från topp till tå, inifrån och ut. En enkel behandling med imponerande effekt.
      Metoden är enkel men patienten känner sig gladare och mer tillfreds redan efter den första behandlingen.
      Den tydligaste effekten av en zonterapibehandling är en känsla av djup avspänning. Den känslan är också det viktigaste
      om du vill gå vidare och få hjälp med eventuella problem med inre organ. Det bästa av allt är att även om du söker
      hjälp för ett specifikt problem, kommer du att få en mångfald av positiva effekter när välbefinnandet sprider sig i
      hela kroppen och det endokrina systemet ger dig ny energi och nya perspektiv på livet. (Den här meningen är lite kryptisk
      och verkar inte heller helt komplett. Kolla att min översättning stämmer med din intention! Den engelska texten innehåller
      en hel del fel. Jag rekommenderar att någon med engelska som modersmål får gå igenom och korrigera.)`
      ,
      `Claudios målsättning är att ge varje patient en personligt avpassad behandling av högsta kvalitet, bättre hälsa,
      ökat välbefinnande och en bättre kroppskännedom.`
    ]
  },

  testimonials: {
    title: 'Vittnesmål',
    list: [
      {
        made_by: 'Kerstin Rosén - Stockholm, Sweden',
        body: `Under en arbetsmässigt mycket påfrestande och tung tid för ca 15 år sedan fick jag möjligheten att regelbundet
        få fotzonterapibehandlingar av Claudio.
        Från första behandlingen var effekten omedelbar.
        En underbar avspänning spred sig i kroppen efter bara en liten stund i Claudios kunniga och känsliga händer.
        Sedan dess har jag sökt mig till Claudios behandlingar så snart han har varit hemma i Sverige.
        Inget annat ger ett sådant välbefinnande.`
      },
      english.testimonials.list[0],
      english.testimonials.list[1],
      english.testimonials.list[2],
      english.testimonials.list[3],
      english.testimonials.list[4],
      english.testimonials.list[5],

    ]
  },

  references: {
    title: 'Referenser',
    list: [
      {
        title: 'Les Eccos',
        body: 'En behandling av Claudio I Zonterapi på Sixsences Douro Valley i Portugal'
      },
      {
        title: 'The Telegraph',
        body: `Utnämnd till en av världens 10 bästa terapeuter`
      },
      {
        title: 'Vogue Brasil',
        body: `I sitt hemland Brasilien har Claudio blivit omskriven i Vogue Brazil`
      },
      {
        title: 'O Corpo Ballet',
        body: `Har turnerat med den berömda brasilianska baletten O Corpo som deras Massageterap`
      },
      {
        title: "Raison d'Etre",
        body: `Har arbetat på Raison d’Être på Grand Hôtel i Stockholm`
      },
      {
        title: 'Sturebadet',
        body: `Har arbetat i två år på Sturebadets exklusiva spa i centrala Stockholm`
      },
      {
        title: 'The Huffington Post',
        body: `År 2011 omnämndes Claudio i the Huffington Post UK`
      }
    ]
  },

  contact: {
    title: 'Kontakt',
    body: `Som en ny Visiting praticioner på Six Senses Spa och Hotel så är jag ofta på resande fot och är därför inte alltid tillgänglig via telefon.
      Skicka därför gärna ett mail istället så återkommer jag så fort som möjligt. Vi hörs!`,
    list: english.contact.list
  }
}
