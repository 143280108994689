import { english } from './english'

export const spanish = {

  navigation: [
    { id: 'header', label: 'Start', href: '#' },
    { id: 'testimonials', label: 'Testimonios', href: '#testimonials' },
    { id: 'contact', label: 'Contacto', href: '#contact' },
    { id: 'treatments', label: 'Tratamiento', href: '#treatments' },
    { id: 'about', label: 'Más sobre Claudio', href: '#about' },
  ],

  languages: {
    title: 'Languages',
    body: [
      { label: 'English', href: '/en', code: 'en' },
      { label: 'Português', href: '/pt', code: 'pt' },
      { label: 'Svenska', href: '/sv', code: 'sv' },
      { label: 'Français', href: '/fr', code: 'fr' },
      { label: 'Español', href: '/es', code: 'es' },
    ]
  },

  currentWork: {
    text: `Claudio J De Menezes trabaja como Terapeuta Invitado (Visiting  practitioner)
            y se puede reservar a través de los establecimientos a continuación`,
    list: english.currentWork.list
  },

  intro: {
    title: 'Claudio J De Menezes',
    subtitle: 'Masajísta y reflexólogo',
    image: english.intro.image,
    body: [
      `Gracias a su toque curativo y a sus tratamientos especiales, Claudio J De Menezes ha sido calificado,
      por The Telegraph, como uno de los 10 mejores terapeutas del mundo. Claudio cuenta con más de 20 años de
      experiencia en el campo de la Reflexología y de otras terapias de sanación.`
      ,
      `Claudio tiene la habilidad de crear una atmósfera única de confort y discreción.
      Para él es muy importante conseguir siempre el trato apropiado y ajustado para cada persona.
      Gracias a ello, y a su maravillosa personalidad, ha logrado crearse una reputación a nivel mundial.
      Un encuentro con él deja una grata impresión y una gran sonrisa`
    ]
  },

  about: {
    title: 'más sobre Claudio',
    image: english.about.image,
    body: [
      `Claudio nació en Minas Gerais, en Brasil. Llegó a Europa en 1987, dónde trabajó inicialmente como organero en Francia. En éste país y en España realizó estudios de anatomía, fisiología, fisioterapia y reflexología podal;  recibiendo estos conocimientos de maestros europeos y chinos.`
      ,
      `La andadura profesional de Claudio en el campo de la salud tiene un marcado caracter internacional, ya que ha realizado su trabajo en Letonia, Bélgica, España, Brasil, Suecia...etc. Una larga lista de países que sigue aumentando debido tanto a su deseo personal de viajar como a su reconocido prestigio internacional.`
      ,
      `La prensa se ha hecho eco de su labor, siendo ésta destacada en la edición brasileña de la revista Vogue y en el diario británico The Telegraph. Éste último le ha reconocido como uno de los mejores terapeutas del mundo.`
      ,
      `Claudio ha trabajado de forma continuada en algunos de los mejores spas de Suecia: en el Sturebadet y en el exclusivo Raison d´Etre /Nordic Spa del Grand Hotel de Estocolmo. También ha trabajo como masajista oficial del Ballet Brasileño O Corpo, en sus giras por Brasil y Europa.`
      ,
      `\"Usted ya sabe ahora quién soy y cómo trabajo. Si tiene interés en la Reflexología o en lo que ésta puede hacer por usted no dude en ponerse en contacto conmigo. Tendremos un cita para conocer cuáles son sus objetivos. Estoy dispuesto a cuidar de Vd.
Le prometo un tiempo de descanso, especialmente para usted, para cuidar de su salud y bienestar.`,
      `¡Nos vemos!\"`
    ]
  },

  treatments: {
    title: 'Reflexología, ¡Lograr la harmonía!',
    subtitle: 'For Health and Wellbeing',
    images: english.treatments.images,
    body: [
      `El objetivo de Claudio es mejorar la salud, aumentar el bienestar y
      el conocimiento del propio cuerpo; dando a cada cliente un trato personalizado de la más alta calidad.`
      ,
      `Hoy en día es muy necesario cuidar nuestra salud, ya que se adquieren fácilmente
      hábitos inadecuados: poco descanso, alimentación inapropiada y ejercicio físico practicado sólamente de forma ocasional.
      Tanto si Vd. necesita cambiar estos hábitos como si sólamente busca cuidar su salud,
      puede recurrir a la Reflexología Podal. Con tan sólo un par de horas al mes ya se obtienen beneficios para la salud.`
      ,
      `La Reflexología Podal se ha hecho muy popular en la última decada,
      sinembargo sus orígenes documentados se remontan a unos 1.000 años antes de Jesucristo, en China.
      El hecho de que todavía exista en esta cultura y que se haya extendido con gran éxito por todo el planeta son la mejor garantía de
      su utilidad para mejorar la vida de las personas.`
      ,
      `Un reflexólogo parte de los pies para mejorar la salud de todo el cuerpo. A través de los puntos reflejos,
      que se encuentran en la planta de los pies, se activan o se relajan los órganos internos que sea necesario.
      El resultado va más allá del lugar dónde se realiza el masaje, haciéndole sentirse mejor de los pies a la
      cabeza y de fuera hacia dentro. Cuerpo, mente y espíritu se beneficiarán de esta técnica.`
      ,
      `Un tratamiento simple con unos benefícios sorprendentes. ¡El primer resultado de un tratamiento adecuado con Reflexología es,
      sobre todo, una profunda sensación de relajación!`
      ,
    ]
  },

  testimonials: {
    title: 'Testimonios',
    list: english.testimonials.list
  },

  references: {
    title: 'referencias',
    list: [
      {
        title: 'Les Eccos',
        body: 'Experiencia en una visita con el Reflexologo Claudio en el Sixsences Del Valle del Douro en Portugal'
      },
      {
        title: 'The Telegraph',
        body: `En 2010 Claudio fue nomeado por el periodic britânico The Telegraph como uno de los diez mejores terapeutas del mundo`
      },
      {
        title: 'Vogue Brasil',
        body: `En Brasil su país natal fué mencionado en la revista Vogue Brasil en noviembre del 2010`
      },
      {
        title: 'O Corpo Ballet',
        body: `Ya acompanyó y trabajo como terapeuta al famoso Grupo de ballet O Corpo de Brasil por sus turnés por Europa`
      },
      {
        title: "Raison d'Etre",
        body: `Trabajó en la empresa Raison d'Etre en el Grand Hotel de Estocolmo`
      },
      {
        title: 'Sturebadet',
        body: `Trabajó durante dos años en el exclusivo spa Sturebadet en el centro de Estocolmo`
      },
      {
        title: 'The Huffington Post',
        body: `Em setembro de 2011 foi mencionado em outro jornal Britânico o The Huffington Post UK`
      }
    ]
  },

  contact: {
    title: 'Contacto',
    body: `Debido a la naturaleza de mi trabajo como meimbro del grupo de (visiting praticioners)en la cadena Six Senses Spas y Hoteles y a mis frecuentes viajes, la forma
    más rápida de contactar conmigo es enviándome un email a: Claudius6tus@yahoo.se Le responderé con la mayor brevedad posible.`,
    list: english.contact.list
  }
}
