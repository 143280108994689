import { english } from './english'

export const french = {

  navigation: [
    { id: 'header', label: 'Start', href: '#' },
    { id: 'testimonials', label: 'Témoignages', href: '#testimonials' },
    { id: 'contact', label: 'Contact', href: '#contact' },
    { id: 'treatments', label: 'Traitements', href: '#treatments' },
    { id: 'about', label: 'Plus sur Claudio', href: '#about' },
  ],

  languages: {
    title: 'Languages',
    body: [
      { label: 'English', href: '/en', code: 'en' },
      { label: 'Português', href: '/pt', code: 'pt' },
      { label: 'Svenska', href: '/sv', code: 'sv' },
      { label: 'Français', href: '/fr', code: 'fr' },
      { label: 'Español', href: '/es', code: 'es' },
    ]
  },

  currentWork: {
    text: `Claudio J De Menezes travaille en tant que praticien
            en visite (Visiting  practitioner) et peut être réservé via les établissements ci-dessous`,
    list: english.currentWork.list
  },

  intro: {
    title: 'Claudio J De Menezes',
    subtitle: 'Massotherapeute et réflexologue',
    body: [
      `Ses dons exceptionnels de toucheur thérapeutique et de masseur énergétique ont donné à
      Claudio J De Menezes d’être reconnu par le Telegraph comme l’un des dix meilleurs thérapeutes au monde.
      Claudio J De Menezes a vingt ans d’expérience comme réflexologue et thérapeute corporel.
      Il porte un soin tout particulier à créer pour ses patients une atmosphère agréable et discrète.
      Une approche individualisée adaptée aux besoins de ses clients est un élément essential de sa pratique.
      Une personnalité d’une rare sensibilité et une méthode originale qui ont fait leurs preuves dans le monde entier.
      Une rencontre qui vous marquera et dont vous garderez la trace d’un sourire chaleureux.`
    ],
    image: english.intro.image
  },

  about: {
    title: 'plus sur Claudio',
    image: english.about.image,
    body: [
      `Claudio J. De Menezes  naît à Belo Horizonte (province du Minas Gerais) au Brésil. D une famille  de 6 enfants,
      grandit à Mariana ville qui n'était dans les années 70 qu'une petite ville a Mariana oú tout a comencer et oú Claudio fait ses classes
      primaires et secondaires Lors de la reinauguration de l'orgue Arph Schinitger en 1984 il as ces premieres rencontres avec des Europeans
      qui lui feront decouvrir son premier metier en Europe. En 1987 il entame une carrière de restaurateur d'orgues baroques La Facture d'orgues
      (le Brésil, et en particulier le Minas Gerais, possède en effet un remarquable patrimoine d'orgues de la fin du  XVIIe siècle) et part dans ce but
      recevoir en France les leçons des maîtres facteurs  D'orgues. Robert Chauvin a Dax (Landes)`
      ,
      `Dans une civilisation comme celle de l'Occident, sans tradition de bien-être corporel, la vocation d'un thérapeute ne se révèle souvent que par des coups
      de chance, des rencontres imprévues. C'est lors de ses études en France qu'un premier contact avec les médecines alternatives éveille en lui une curiosité
      pour des techniques thérapeutiques anciennes telles que la pressopuncture et la réflexologie plantaire: en effet, en 1988, à l'occasion d'une violente crise
      anginale, une famille d'amis asiatiques de la région parisienne le fait soigner par un médecin chinois de ses familiers. Les résultats dépassent toutes les
      attentes...`
      ,
      `Il faudra cependant encore que beaucoup d'années passent pour qu'il se décide à découvrir l’anatomie et le massage et se plonge dans des études approfondies
      de réflexologie podale dans un objectif thérapeutique : il étudie  a Madrid Centre Orthos  et acquière une connaissance premiére de l'anatomie. Puis cherche
      a aprendre plus et plus sur  les pieds, des organes internes et de la physiologie. En 1997, à Riga, il débute sa carrière de masseur réflexologue. Apres avoir
      fait des cours en Espagne, France  et Suede.`
      ,
      `Le chemin de la facture d'orgue à la réflexologie a été long et a abondé en expériences variées. Lors de son apprentissage en Europe, en Asie et dans les
      Amériques du Sud et du Nord, Claudio a été constamment soutenu par sa passion des voyages et des rencontres et n'a cessé d'échanger expérience pratique et
      théorique avec collègues et clients du monde entier.Sa formation s'est poursuivie depuis sans interruption par son activité dans différents spas de Suède et
      du Brésil et il donne désormais en indépendant des séminaires de formation dans les pays de l'Europe du Nord et de l'Amérique du sud.`
      ,
      `En 1995  Claudio De Menezes fonde le cabinet Plexus Friskvård à Stockholm, capitale d'un pays dont il possède la nationalité pour des raisons privées
      depuis plusieurs années et un des grands centres de recherche paramédicale dans le monde. Plexus -Sanitas devient une base de travail pour son activité
      thérapeutique internationale. Claudio J. De Menezes  se consacre désormais principalement aux clients privés de son cabinet Plexus Sanitas mais continue
      sa collaboration fructueuse avec Sturebadet, Raison d'être / Nordic spa  et le spa exclusif du Grand Hôtel de Stockholm ainsi qu'avec plusieurs hôtels et
      spas de son pays natal et des États-Unis. Ceci constitue ainsi une chaîne de fidèles qui valident la qualité de son travail. Suite à la visite du journal
      britannique The Telegraph, Claudio J.De Menezes est élu un des 10 plus grands thérapeutes alternatifs du monde. En 2011, The Huffington Post mentionne
      également Claudio De Menezes parmi une liste de thérapeutes fameux à Stockholm.`
      ,
      `Cette appréciation est le résultat d'une pratique de 20 années dans de nombreux  pays et dont le mérite revient autant aux patients qu'au thérapeute.
      En effet, sans communication de ses patients, aucun thérapeute ne peut "trouver et sa voie" et avancer. Les témoignages soit de satisfaction
      soit d’insatisfaction sont donc une partie indispensable de la pratique quotidienne d'un thérapeute. Oser dire et avoir le courage d'entendre sont des
      règles de base pour qui recherche le bien-être corporel. Vivant et travaillant en 5 langues (le suédois, le portugais, l'espagnol, le français et l'anglais),
      Claudio J.De Menezes a pratiqué cette règle d'or du langage libre depuis le début de sa carrière.`
      ,
      `Claudio J. De Menezes intervient plusieurs fois par an comme thérapeute pour la compagnie de danse O Corpo et l'accompagne
      lors de ses tournées en Europe et au Brésil.`
      ,
      `Claudio J. De Menezes délivre régulièrement des formations pour de jeunes élèves thérapeutes, dans le cadre de la transmission de la tradition,
      et s'engage pour faire reconnaître les thérapies alternatives par les systèmes de santé publics dans le monde.`
    ]
  },

  treatments: {
    title: "Réflexologie - atteignez l'harmonie!",
    subtitle: 'For Health and Wellbeing',
    images: english.treatments.images,
    body: [
      `  L'objectif de Claudio est de donner à chaque client un traitement de la plus grande qualité, basé sur les spécificités de chaque individu.`
      ,
      `Il est bien connu que nous avons tendance à vivre sans nous soucier suffisamment de notre bien être.
      Beaucoup d'entre nous ne dorment pas suffisamment, mangent mal, font trop peu d'exercice, voire pas du tout.`
      ,
      `La vraie question est de savoir comment vous vivez, et comment vous vous sentez.`
      ,
      `Parfois, il suffit de très petits changements dans votre vie pour améliorer celle-ci de manière notable. Grâce à un
      rythme de une à deux séances de réflexologie par mois, vous atteindrez de grands résultats en gagnant un mieux-être et une meilleure santé.`
      ,
      `Ce type de traitement a beau être "à la mode" en ce moment, il existe pourtant depuis plus de 3000 ans! La
      réflexologie prend compte de votre corps dans son
      intégralité. En réflexologie, le corps, l'âme et l'esprit sont aussi importants les uns que les autres.`
      ,
      `Ce traitement, dit holistique, n'est pas qu'un traitement "trendy". Il n'est pas non plus un traitement moderne, bien au contraire :
      en Chine et dans d'autres partie de l'Asie, il est pratiqué depuis 1000 ans avant notre ère. Le fait que que ce type de soin soit
      toujours pratiqué dans le monde et qu'il se soit diffusé sur l'ensemble du globe est un témoin du fait qu'il parvient à un résultat
      patent sur les personnes qui s'y intéressent. Les pieds d'abord! Mais l'entièreté du corps est pourtant en ligne de mire.`
      ,
      `Un réflexologue se concentre sur les pieds : à travers des zones bien définies sur l'ensemble du pied,
      le thérapeute intervient sur des zones du corps correspondantes et les stimule ou les décontracte suivant
      les besoins du corps. A travers un shéma de réseaux, vos organes, glandes et autres peuvent être sollicités.`
      ,
      `La réflexologie est un exemple typique de ce que l'on appelle une méthode holistique.
      Elle aide à vous sentir mieux des pieds à la tête. Un simple traitement avec des effets étonnants.
      Même avec une méthode simple, les effets se feront sentir dès la première séance: vous vous sentirez mieux, et plus heureux.
      Le premier effet du traitement est avant tout un sentiment de profonde relaxation. Cette sensation est aussi la clé qui permet
      d'atteindre les organes au plus profond de votre corps.`
      ,
      `Un autre avantage de ce type de traitement holistique est aussi que si vous venez pour une problématique particulière sur un organe spécifique,
      l'ensemble du corps s'en retrouvera réharmonisé, avec une multitude de petites améliorations non négligeables,
      en particulier sur votre système endocrinien qui vous donnera une nouvelle énergie et de nouvelles perspectives dans votre vie.`
      ,
      `Le but de Claudio est de donner à chaque client un traitement qui lui est adapté, en repectant les exigeances les plus élevées en terme de qualité,
      parvenant ainsi à améliorer la santé, le bien-être et la conscience de son propre corps.`
    ]
  },

  testimonials: {
    title: 'Témoignages',
    list: english.testimonials.list
  },

  references: {
    title: 'RÉFÉRENCES',
    list: [
      {
        title: 'Les Eccos',
        body: 'Sur leSixsences de la vallée du Douro au Portugal avec le reflexologue de légende Claudio Menezes'
      },
      {
        title: 'The Telegraph',
        body: `Cité comme l’un des 10 meilleurs thérapeutes du monde`
      },
      {
        title: 'Vogue Brasil',
        body: `Publié dans VOGUE, au Brésil`
      },
      {
        title: 'O Corpo Ballet',
        body: `Masseur officiel de l’équipe de ballet brésilien qu’ il a accompagné  en tournées`
      },
      {
        title: "Raison d'Etre",
        body: `A travaillé à Raison d’Être, au Grand Hotel de Stockholm`,
      },
      {
        title: 'Sturebadet',
        body: `A travaillé pendant deux ans au exclusif SPA Sturebadet, au centre de Stockholm`
      },
      {
        title: 'The Huffington Post',
        body: `Cité en septembre 2011 dans le Huffington Post, Royaume Uni`
      }
    ]
  },

  contact: {
    title: 'Me Contacter',
    body: `Voyageant beaucoup, surtout depuis que je suis un nouveau membre des (Visiting praticioners) de la compagnie de Spas et Hotels Six Senses
    je ne suis pas toujours en mesure de répondre au téléphone.
    Je vous propose de me contacter par mon e.mail et je vous recontacterai au plus vit possible et repondrais a votre message.`,
    list: english.contact.list
  }
}
